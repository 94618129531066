import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { auth } from "../index";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  TextField,
  Button,
  Typography,
  Container,
  styled,
} from "@mui/material";
import "firebase/auth";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(4),
}));

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  maxWidth: "300px",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  const register = () => {
    navigate("/register");
  };

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setLoggedIn(true);
    } catch (error) {
      console.error("Authentication error:", error);
    }
  };

  if (loggedIn) {
    return <Navigate to="/test" />;
  }

  return (
    <StyledContainer>
      <Typography variant="h4">Login Page</Typography>
      <StyledForm>
        <TextField
          type="text"
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          type="password"
          label="Password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <StyledButton variant="contained" color="primary" onClick={handleLogin}>
          Log In
        </StyledButton>
        <StyledButton variant="contained" color="secondary" onClick={register}>
          Register
        </StyledButton>
      </StyledForm>
    </StyledContainer>
  );
};

export default LoginPage;
