import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../index";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  Container,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const StrikeThrough = styled("span")({
  textDecoration: "line-through",
});

const Test: React.FC = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(user?.email);
  }, [user]);

  const renderTest = (testNumber: number) => {
    navigate(`/testpage?testNumber=${testNumber}`);
  };

  const renderTableRow = (
    testNumber: number,
    strikeThroughPrice: number,
    price: number
  ) => (
    <TableRow key={testNumber}>
      <TableCell>{testNumber}</TableCell>
      <TableCell>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => renderTest(testNumber)}
        >
          Start
        </StyledButton>
      </TableCell>
      <TableCell>N/A</TableCell>
      <TableCell>
        <Button variant="contained" color="primary">
          Purchase
        </Button>{" "}
        <Typography variant="body2" color="textSecondary" display="inline">
          <StrikeThrough>${strikeThroughPrice}</StrikeThrough>{" "}
        </Typography>
        <Typography variant="body2" color="textPrimary" display="inline">
          ${price}
        </Typography>
      </TableCell>
    </TableRow>
  );

  const testRows = Array.from({ length: 10 }, (_, index) =>
    renderTableRow(index + 1, 65.99, 49.99)
  );

  return (
    <StyledContainer>
      <Typography variant="h3">Available Tests</Typography>
      <TableContainer>
        <Table>
          <TableBody>{testRows}</TableBody>
        </Table>
      </TableContainer>
    </StyledContainer>
  );
};

export default Test;
