import React from "react";
import visa from "../Images/visa.png";
import mastercard from "../Images/mastercard.png";

const Footer = () => {
  return (
    <footer
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        padding: "20px",
        backgroundColor: "#f0f0f0",
        textAlign: "center",
      }}
    >
      <p>© {new Date().getFullYear()} MCAT PREP HQ</p>
      <p>
        <a href="/">Home</a> | <a href="/privacy-policy">Privacy Policy</a> |{" "}
        <a href="/terms-and-conditions">Terms and Conditions</a> |{" "}
        <a href="/description-of-goods">Description of Goods</a> |{" "}
        <a href="/return-refund-policy">Return/Refund Policy</a>
      </p>
      <img src={visa} alt="Visa" style={{ width: "50px", height: "auto" }} />
      <img
        src={mastercard}
        alt="Mastercard"
        style={{ width: "50px", height: "auto" }}
      />
    </footer>
  );
};

export default Footer;
