import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import LoginPage from "./LoginPage";
import Test from "./Test";
import TestPage from "./TestPage";
import Register from "./Register";
import { useAuthState } from "react-firebase-hooks/auth";
import Footer from "./Footer";
import ServiceDescription from "./ServiceDescription";
import TermsAndConditions from "./TermsAndConditions";
import RefundPolicy from "./RefundPolicy";
import PrivacyPolicy from "./PrivacyPolicy";

import { auth } from "../index";

const App: React.FC = () => {
  const [user] = useAuthState(auth);

  return (
    <div style={{ paddingBottom: "100px" }}>
      {" "}
      <Router>
        <Routes>
          <Route
            path="/"
            element={user ? <Navigate to="/test" /> : <Navigate to="/login" />}
          ></Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/test" element={<Test />} />
          <Route path="/testpage" element={<TestPage />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/description-of-goods"
            element={<ServiceDescription />}
          />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/return-refund-policy" element={<RefundPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
