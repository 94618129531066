import React, { useState, useEffect } from "react";
import { Passage } from "../Interfaces/Passage";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Grid,
  Paper,
} from "@mui/material";

const TestPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const testNumber = searchParams.get("testNumber");
  const [showExplanations, setShowExplanations] = useState(false);
  const [isTestFinished, setIsTestFinished] = useState(false);
  const [passageData, setPassageData] = useState<Passage | null>(null);
  const [passageNumber, setPassageNumber] = useState(1);

  useEffect(() => {
    loadJsonData(`/tests/test${testNumber}/passage${passageNumber}.json`);
  }, [passageNumber]);

  const loadJsonData = async (url: string) => {
    try {
      const response = await fetch(url);
      const data: Passage = await response.json();
      setPassageData(data);
    } catch (error) {
      console.error("Error loading JSON data:", error);
    }
  };

  const handleFinishTest = () => {
    const count = getAllAnswersCount();
    console.log(count);
    setShowExplanations(true);
    setIsTestFinished(true);
  };

  const getAllAnswersCount = (): number => {
    let totalCount = 0;
    Object.values(allAnswers).forEach((passageAnswers) => {
      Object.values(passageAnswers).forEach(() => {
        totalCount++;
      });
    });
    return totalCount;
  };

  const [allAnswers, setAllAnswers] = useState<{
    [passage: string]: { [question: string]: string };
  }>({});
  const [selectedAnswers, setSelectedAnswers] = useState<{
    [key: string]: string;
  }>({});
  const [timeRemaining, setTimeRemaining] = useState(90 * 60);

  useEffect(() => {
    setSelectedAnswers(allAnswers[`passage${passageNumber}`] || {});
  }, [passageNumber, allAnswers]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => Math.max(prevTime - 1, 0));
      if (timeRemaining === 0) {
        clearInterval(timerInterval);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timeRemaining]);

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleOptionChange = (
    questionNumber: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedAnswers = {
      ...selectedAnswers,
      [questionNumber]: event.target.value,
    };
    setSelectedAnswers(updatedAnswers);
    setAllAnswers((prev) => ({
      ...prev,
      [`passage${passageNumber}`]: updatedAnswers,
    }));
  };

  return (
    <>
      {passageData && (
        <div>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h4" gutterBottom>
                {passageData.test.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color={timeRemaining <= 300 ? "error" : "initial"}
              >
                {formatTime(timeRemaining)}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="body1" gutterBottom>
            {passageData.test.instructions}
          </Typography>

          <Grid container spacing={4}>
            {/* Passage section (left) */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} style={{ padding: "16px" }}>
                <Typography variant="h5" gutterBottom>
                  {passageData.test.passage.title}
                </Typography>
                <Typography variant="body1">
                  <strong>Author:</strong> {passageData.test.passage.author}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Source: </strong>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: passageData.test.passage.source,
                    }}
                  />
                </Typography>
                {passageData.test.passage.paragraphs.map((paragraph, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    paragraph
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                  />
                ))}
              </Paper>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={() => setPassageNumber(passageNumber - 1)}
                  disabled={passageNumber === 1}
                  style={{ marginRight: "10px" }}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFinishTest}
                  style={{ marginRight: "10px" }}
                >
                  Finish Test
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setPassageNumber(passageNumber + 1)}
                  disabled={passageNumber === 9}
                >
                  Next
                </Button>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
                  <Button
                    key={number}
                    variant="contained"
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                    onClick={() => setPassageNumber(number)}
                  >
                    {number}
                  </Button>
                ))}
              </div>
            </Grid>

            {/* Questions section (right) */}
            <Grid item xs={12} md={6}>
              {passageData.test.questions.map((question, index) => (
                <div key={index}>
                  <Typography variant="body1" gutterBottom>
                    <strong>Question {question.number}:</strong>{" "}
                    <span dangerouslySetInnerHTML={{ __html: question.text }} />
                  </Typography>

                  <FormControl component="fieldset" margin="normal">
                    <RadioGroup
                      aria-label={`question-${index}`}
                      value={selectedAnswers[question.number] || ""}
                      onChange={(event) =>
                        handleOptionChange(question.number, event)
                      }
                    >
                      {question.options.map((option, optionIndex) => (
                        <>
                          <FormControlLabel
                            key={optionIndex}
                            value={option.letter}
                            control={<Radio />}
                            label={
                              <React.Fragment>
                                {`${option.letter}: `}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: option.option,
                                  }}
                                />
                              </React.Fragment>
                            }
                          />
                          {showExplanations && (
                            <Typography
                              variant="body1"
                              style={{
                                color:
                                  option.letter === question.correctAnswer
                                    ? "blue"
                                    : "red",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: option.explanation,
                              }}
                            />
                          )}
                          <br></br>
                        </>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  {index !== passageData.test.questions.length - 1 && (
                    <Divider style={{ margin: "20px 0" }} />
                  )}
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default TestPage;
